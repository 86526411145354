import convert from 'convert-units'
import moment from 'moment-timezone'
import { isProxy , toRaw } from 'vue'
import i18n from '../i18n/i18n'

export default {
  
  moment (str) { // Datetime as string
    const timeZone = this.getTimezone()
    let momentDate = moment(str)
    momentDate = moment.tz(momentDate, timeZone)
    return momentDate
  },

  getTimezone () {
    // Check if queryParams contains the timezone
    let timeZone = null
    const urlParams = window.location.href.split('?').length > 1 ? new URLSearchParams(window.location.href.split('?')[1]) : new URLSearchParams()
    if (urlParams.has('timeZone')) { timeZone = urlParams.get('timeZone') }
    // Test timezone
    if (!moment.tz.zone(timeZone)) { timeZone = null }
    timeZone = timeZone || localStorage.getItem('timeZone')
    if (!moment.tz.zone(timeZone)) { timeZone = null }
    // Otherwise try to guess it
    return timeZone || moment.tz.guess()
  },

  getTime (str) { // Datetime as string
    return this.moment(str).valueOf()
  },

  getDisplayTzToServerHourOffset () {
    const serverTz = moment.utc()
    const tz = localStorage.getItem('timeZone') || moment.tz.guess()
    const offset = moment.tz.zone(tz).utcOffset(serverTz) / 60 // From TZ to SERVER
    return offset
  },

  getServerToDisplayTzHourOffset () {
    const serverTz = moment.utc()
    const tz = localStorage.getItem('timeZone') || moment.tz.guess()
    const offset = moment.tz.zone(tz).utcOffset(serverTz) / -60 // From SERVER to TZ
    return offset
  },

  formatDatetime (str) { // Datetime as string
    if (!str) { return null }
    const date = this.moment(str)
    return date
  },

  formatDatetimeToText (str, format = null) { // Datetime as string
    if (!str) { return null }
    const date = this.formatDatetime(str)
    if (format) {
      return date.format(format)
    } else {
      return date.calendar(null, {
        lastDay: '[Yesterday] - HH:mm',
        sameDay: '[Today] - HH:mm',
        nextDay: '[Tomorrow] - HH:mm',
        lastWeek: '[Last] dddd - HH:mm',
        nextWeek: 'dddd - HH:mm',
        sameElse: 'L - HH:mm'
      })
    }
  },

  getTimeZones () {
    return moment.tz.names()
  },

  getDateRangesInIncrements (startDate, endDate, timeUnit = 'hour') {
    const ranges = []
    let iterator = startDate
    while (iterator <= endDate) {
      ranges.push(this.moment(iterator).startOf(timeUnit).toDate())
      iterator = this.moment(iterator).add(1, timeUnit).toDate()
    }
    return ranges
  },

  isNumber (n) {
    return !isNaN(parseFloat(n)) && isFinite(n)
  },

  getMostSpecificUnit () {
    let units = []
    for (let i = 0; i < arguments.length; i++) {
      units.push(arguments[i])
    }
    units = units.filter(u => u != null)
    if (units.length <= 0) { return null }
    let unit = units[0]
    for (let i = 1; i < units.length; i++) {
      const u = units[i]
      if (!u) { continue }
      if (u === unit) { continue }
      if (!unit) { unit = u; continue }
      if (unit.includes(u)) { continue }
      if (u.includes(unit)) { unit = u; continue }
    }
    return unit
  },

  getUnitsStructure () {
    return {
      'janby/unit': ['janby/unit'],
      'janby/unit/serving': ['janby/unit/serving'],
      'janby/unit/slice': ['janby/unit/slice'],
      'janby/unit/leaf': ['janby/unit/leaf'],
      'janby/unit/temperature': ['janby/unit/temperature/C', 'janby/unit/temperature/F'], // , 'janby/unit/temperature/K'],
      'janby/unit/time': ['janby/unit/time'],
      'janby/unit/weight': ['janby/unit/weight/g', 'janby/unit/weight/kg', 'janby/unit/weight/oz', 'janby/unit/weight/lb'],
      'janby/unit/lenght': ['janby/unit/length/cm', 'janby/unit/length/m', 'janby/unit/length/km']
    }
  },

  getUnitName (type, singularPlural = 1) {
    switch (type) {
      // TEMPERATURES
      case 'temperature':
      case 'janby/unit/temperature/':
      case 'janby/unit/temperature': {
        const lc = this.getMostSpecificUnit(localStorage.getItem('temperatureUnit'), 'janby/unit/temperature/C')
        if (lc !== type) { return this.getUnitName(lc, singularPlural) } else { return i18n.global.tc('m.janby/unit/temperature', singularPlural) }
      }
      // WEIGHT
      case 'weight':
      case 'janby/unit/weight/':
      case 'janby/unit/weight': {
        const lc = this.getMostSpecificUnit(localStorage.getItem('weightUnit'), 'janby/unit/weight/g')
        if (lc !== type) { return this.getUnitName(lc, singularPlural) } else { return i18n.global.tc('m.janby/unit/weight', singularPlural) }
      }
      // LENGTH
      case 'length':
      case 'janby/unit/length/':
      case 'janby/unit/length': {
        const lc = this.getMostSpecificUnit(localStorage.getItem('lengthUnit'), 'janby/unit/length/m')
        if (lc !== type) { return this.getUnitName(lc, singularPlural) } else { return i18n.global.tc('m.janby/unit/length', singularPlural) }
      }
      default:
        return i18n.global.tc('m.' + type, singularPlural)
    }
  },

  getNormalizedUnitType (unit) {
    const bases = this.getUnitsStructure()
    for (let key in bases) {
      if (bases[key] && bases[key].includes(unit)) {
        return key
      }
    }
    return unit
  },

  getUnitText (type, singularPlural) {
    switch (type) {
      // TEMPERATURES
      case 'temperature':
      case 'janby/unit/temperature/': 
      case 'janby/unit/temperature': {
        const lc = this.getMostSpecificUnit(localStorage.getItem('temperatureUnit'), 'janby/unit/temperature/C')
        if (lc !== type) { return this.getUnitText(lc, singularPlural) } else { return i18n.global.tc('m.janby/unit/temperature', singularPlural) }
      }
      case 'janby/unit/temperature/C/':
      case 'janby/unit/temperature/C':
        return 'ºC'
      case 'janby/unit/temperature/F/':
      case 'janby/unit/temperature/F':
        return 'ºF'
      case 'janby/unit/temperature/K/':
      case 'janby/unit/temperature/K':
        return 'ºK'

      // TIME
      case 'janby/unit/time':
      case 'janby/unit/duration':
        return 's'

      // WEIGHT
      case 'weight':
      case 'janby/unit/weight/':
      case 'janby/unit/weight': {
        const lc = this.getMostSpecificUnit(localStorage.getItem('weightUnit'), 'janby/unit/weight/g')
        if (lc !== type) { return this.getUnitText(lc, singularPlural) } else { return i18n.global.tc('m.janby/unit/weight', singularPlural) }
      }
      case 'janby/unit/weight/g/':
      case 'janby/unit/weight/g':
        return 'g'
      case 'janby/unit/weight/kg/':
      case 'janby/unit/weight/kg':
        return 'kg'
      case 'janby/unit/weight/oz/':
      case 'janby/unit/weight/oz':
        return 'oz'
      case 'janby/unit/weight/lb/':
      case 'janby/unit/weight/lb':
        return 'lb'

      // LENGTH
      case 'length':
      case 'janby/unit/length/':
      case 'janby/unit/length': {
        const lc = this.getMostSpecificUnit(localStorage.getItem('lengthUnit'), 'janby/unit/length/m')
        if (lc !== type) { return this.getUnitText(lc, singularPlural) } else { return i18n.global.tc('m.janby/unit/length', singularPlural) }
      }
      case 'janby/unit/length/cm/':
      case 'janby/unit/length/cm':
        return 'cm'
      case 'janby/unit/length/m/':
      case 'janby/unit/length/m':
        return 'm'
      case 'janby/unit/length/km/':
      case 'janby/unit/length/km':
        return 'km'

      // UNIT
      case 'unit':
      case 'janby/unit/':
      case 'janby/unit':
      case 'janby/unit/serving/':
      case 'janby/unit/serving':
      case 'janby/unit/slice/':
      case 'janby/unit/slice':
      case 'janby/unit/leaf/':
      case 'janby/unit/leaf':
      case 'janby/unit/calory/':
      case 'janby/unit/calory':
      default:
        return i18n.global.tc('m.' + type, singularPlural)
    }
  },

  // TEMPERATURE

  janbyTemperatureUnitToLib (tempetatureUnit) {
    switch (tempetatureUnit) {
      case 'temperature':
      case 'janby/unit/temperature/':
      case 'janby/unit/temperature':
      case 'janby/unit/temperature/C/':
      case 'janby/unit/temperature/C':
      case 'celsius':
      case 'C':
        return 'C'
      case 'janby/unit/temperature/F/':
      case 'janby/unit/temperature/F':
      case 'fahrenheit':
      case 'F':
        return 'F'
      case 'janby/unit/temperature/K/':
      case 'janby/unit/temperature/K':
      case 'kelvin':
      case 'K':
        return 'K'
    }
    return tempetatureUnit
  },

  formatTemperature (celsius, decimals = 1) { // Given in celsius always
    if (celsius === undefined || celsius === null) { return null }
    const temperatureUnit = this.getMostSpecificUnit(localStorage.getItem('temperatureUnit'), 'janby/unit/temperature/C')
    const converted = convert(celsius).from('C').to(this.janbyTemperatureUnitToLib(temperatureUnit))
    return this.roundDecimals(converted, decimals)
  },

  formatTemperatureToText (celsius, decimals = 1) { // Given in celsius always
    if (celsius === undefined || celsius === null) { return null }
    const temperatureUnit = this.getMostSpecificUnit(localStorage.getItem('temperatureUnit'), 'janby/unit/temperature/C')
    return this.formatTemperature(celsius, decimals) + ' ' + this.getUnitText(temperatureUnit)
  },

  convertTemperature (value, fromUnit, toUnit) {
    if (value === undefined || value === null) { return value }
    const converted = convert(value).from(this.janbyTemperatureUnitToLib(fromUnit)).to(this.janbyTemperatureUnitToLib(toUnit))
    return converted
  },

  // WEIGHT

  janbyWeightUnitToLib (weightUnit) {
    switch (weightUnit) {
      case 'weight':
      case 'janby/unit/weight/':
      case 'janby/unit/weight':
      case 'janby/unit/weight/g/':
      case 'janby/unit/weight/g':
      case 'gram':
      case 'g':
        return 'g'
      case 'janby/unit/weight/kg/':
      case 'janby/unit/weight/kg':
      case 'kilogram':
      case 'kg':
        return 'kg'
      case 'janby/unit/weight/oz/':
      case 'janby/unit/weight/oz':
      case 'ounce':
      case 'oz':
        return 'oz'
      case 'janby/unit/weight/lb/':
      case 'janby/unit/weight/lb':
      case 'pound':
      case 'lb':
        return 'lb'
    }
    return weightUnit
  },

  formatWeight (grams, decimals = 2) { // Given in grams always
    if (grams === undefined || grams === null || isNaN(grams)) { return null }
    const weightUnit = this.getMostSpecificUnit(localStorage.getItem('weightUnit'), 'janby/unit/weight/g')
    return this.roundDecimals(convert(grams).from('g').to(this.janbyWeightUnitToLib(weightUnit)), decimals)
  },

  formatWeightToText (grams, decimals = 2) { // Given in grams always
    if (grams === undefined || grams === null) { return null }
    const weightUnit = this.getMostSpecificUnit(localStorage.getItem('weightUnit'), 'janby/unit/weight/g')
    return this.formatWeight(grams, decimals) + ' ' + this.getUnitText(weightUnit)
  },

  convertWeight (value, fromUnit, toUnit) {
    if (value === undefined || value === null) { return value }
    const converted = convert(value).from(this.janbyWeightUnitToLib(fromUnit)).to(this.janbyWeightUnitToLib(toUnit))
    return converted
  },

  // LENGTH

  janbyLengthUnitToLib (lengthUnit) {
    switch (lengthUnit) {
      case 'length':
      case 'janby/unit/length/':
      case 'janby/unit/length':
      case 'janby/unit/length/m/':
      case 'janby/unit/length/m':
      case 'meter':
      case 'm':
        return 'm'
      case 'janby/unit/length/cm/':
      case 'janby/unit/length/cm':
      case 'centimeter':
      case 'cm':
        return 'cm'
    }
    return lengthUnit
  },

  formatlength (meters, decimals = 2) { // Given in meters always
    if (meters === undefined || meters === null || isNaN(meters)) { return null }
    const lengthUnit = this.getMostSpecificUnit(localStorage.getItem('lengthUnit'), 'janby/unit/length/m')
    return this.roundDecimals(convert(meters).from('m').to(this.janbyLengthUnitToLib(lengthUnit)), decimals)
  },

  formatLengthToText (meters, decimals = 2) { // Given in meters always
    if (meters === undefined || meters === null) { return null }
    const lengthUnit = this.getMostSpecificUnit(localStorage.getItem('lengthUnit'), 'janby/unit/length/m')
    return this.formatlength(meters, decimals) + ' ' + this.getUnitText(lengthUnit)
  },

  // DURATION

  formatDurationToText (seconds) { // Given in seconds always
    const duration = moment.duration(seconds, 'seconds')

    const months = Math.floor(duration.asMonths())
    let days = duration.days()
    if (months > 0) {
      return `${months}${' '+i18n.global.tc('m.month', months)} ${days ? days + ' '+i18n.global.tc('m.day', 2)+' ' : ''} (${Math.floor(duration.asDays())} ${i18n.global.tc('m.day', 2)})`
    }

    days = Math.floor(duration.asDays())
    const hours = duration.hours()
    if (days > 0) {
      return `${days}${' '+i18n.global.tc('m.day', days)} ${hours ? hours + ' '+i18n.global.tc('m.hour', 2)+' ' : ''}`
    }

    const mins = duration.minutes()
    if (hours > 0) {
      return `${hours}${' '+i18n.global.tc('m.hour', hours)} ${mins ? mins + ' '+i18n.global.tc('m.minute', 2)+' ' : ''}`
    }

    const secs = duration.seconds()
    if (mins > 0) {
      return `${mins ? mins + ' '+i18n.global.tc('m.minute', mins)+' ' : ''}${secs ? secs + ' '+i18n.global.tc('m.second', secs) : ''}`
    }

    return `${secs > -1 ? secs + ' '+i18n.global.tc('m.second', secs) : ''}`
  },

  formatDurationToCustomizableText (seconds, showD = true, showH = true, showM = true, showS = true) {
    const duration = moment.duration(moment(seconds*1000).diff(moment(0)))
    const d = Math.floor(duration.as('days'))
    const h = Math.floor(duration.as('hours')) % 24
    const m = Math.floor(duration.as('minutes')) % 60
    const s = Math.floor(duration.as('seconds')) % 60
    let str = ''
    if (d >= 0 && showD) { str += d + ' '+i18n.global.tc('m.day', d)+' ' }
    if (h >= 0 && showH) { str += h + ' '+i18n.global.tc('m.hour', h)+' ' }
    if (m >= 0 && showM) { str += m + ' '+i18n.global.tc('m.minute', m)+' ' }
    if ((s >= 0 || (m <= 0 && h <= 0 && d <= 0)) && showS) { str += s + ' '+i18n.global.tc('m.second', s)+' ' }
    return str
  },

  formatDatetimeDifferenceToText (strFrom, strTo, showD = true, showH = true, showM = true, showS = true) { // Date as strings
    if (!strFrom || !strTo) { return null }
    const fromDate = moment(strFrom)
    const toDate = moment(strTo)
    return this.formatDurationToCustomizableText(moment.duration(toDate.diff(fromDate)), showD, showH, showM, showS)
  },

  // OTHERS

  formatNumberToText (unitary, decimals, text) { // Given in numeric always
    return `${this.roundDecimals(unitary, decimals)}${text}`
  },

  // UNIVERSAL FORMATTER
  toFixed (number, decimals = 1) {
    if (!number) { return 0 }
    if (!number.toFixed) { return number }
    if (number % 1 == 0) { return number }
    return number.toFixed(decimals)
  },

  formatAny (value, type, decimals = 2) {
    if (value === null) { return null }
    switch (type) {
      case 'temperature':
      case 'janby/unit/temperature/':
      case 'janby/unit/temperature':
      case 'janby/unit/temperature/C/':
      case 'janby/unit/temperature/C':
        return this.formatTemperature(value, decimals)
      case 'weight':
      case 'janby/unit/weight/':
      case 'janby/unit/weight':
        return this.formatWeight(value, decimals)
      default:
        return this.roundDecimals(value, decimals)
    }
  },

  formatAnyToText (value, type, decimals = 2, categories = []) {
    if (value === null) { return '_' }

    const weightUnit = this.getMostSpecificUnit(localStorage.getItem('weightUnit'), 'janby/unit/weight/g')
    // const temperatureUnit = this.getMostSpecificUnit(localStorage.getItem('temperatureUnit'), 'janby/unit/temperature/C')
    const lengthUnit = this.getMostSpecificUnit(localStorage.getItem('lengthUnit'), 'janby/unit/length/m')

    switch (type) {
      case 'temperature':
      case 'janby/unit/temperature/':
      case 'janby/unit/temperature':
        return this.formatTemperatureToText(value, decimals)
      case 'janby/unit/temperature/C/':
      case 'janby/unit/temperature/C':
        return this.roundDecimals(value, decimals) + ' ' + this.getUnitText('janby/unit/temperature/C')
      case 'janby/unit/temperature/F/':
      case 'janby/unit/temperature/F':
        return this.roundDecimals(this.convertTemperature(value, 'janby/unit/temperature/C', 'janby/unit/temperature/F'), decimals) + ' ' + this.getUnitText('janby/unit/temperature/F')
      case 'duration':
      case 'janby/unit/duration/':
      case 'janby/unit/duration':
      case 'time':
      case 'janby/unit/time/':
      case 'janby/unit/time':
        return this.formatDurationToText(value, decimals)
      case 'weight':
      case 'janby/unit/weight/':
      case 'janby/unit/weight':
        return this.formatWeightToText(value, weightUnit === 'janby/unit/weight/g' ? 0 : 3)
      case 'janby/unit/weight/g/':
      case 'janby/unit/weight/g':
        return this.roundDecimals(value, decimals) + ' ' + this.getUnitText('janby/unit/weight/g')
      case 'janby/unit/weight/kg/':
      case 'janby/unit/weight/kg':
        return this.roundDecimals(this.convertWeight(value, 'janby/unit/weight/g', 'janby/unit/weight/kg'), decimals) + ' ' + this.getUnitText('janby/unit/weight/kg')
      case 'janby/unit/weight/oz/':
      case 'janby/unit/weight/oz':
        return this.roundDecimals(this.convertWeight(value, 'janby/unit/weight/g', 'janby/unit/weight/oz'), decimals) + ' ' + this.getUnitText('janby/unit/weight/oz')
      case 'janby/unit/weight/lb/':
      case 'janby/unit/weight/lb':
        return this.roundDecimals(this.convertWeight(value, 'janby/unit/weight/g', 'janby/unit/weight/lb'), decimals) + ' ' + this.getUnitText('janby/unit/weight/lb')
      case 'length':
      case 'janby/unit/length/':
      case 'janby/unit/length':
        return this.formatLengthToText(value, lengthUnit === 'janby/unit/weight/m' ? 3 : 2)
      case 'unit':
      case 'janby/unit/':
      case 'janby/unit':
        return this.formatNumberToText(value, decimals, ' ' + i18n.global.tc('m.janby/unit', 2))
      case 'serving':
      case 'janby/unit/serving/':
      case 'janby/unit/serving':
        return this.formatNumberToText(value, decimals, ' ' + i18n.global.tc('m.janby/unit/serving', 2))
      case 'slice':
      case 'janby/unit/slice/':
      case 'janby/unit/slice':
        return this.formatNumberToText(value, decimals, ' ' + i18n.global.tc('m.janby/unit/slice', 2))
      case 'leaf':
      case 'janby/unit/leaf/':
      case 'janby/unit/leaf':
        return this.formatNumberToText(value, decimals, ' ' + i18n.global.tc('m.janby/unit/leaf', 2))
      case 'calory':
      case 'janby/unit/calory/':
      case 'janby/unit/calory':
        return this.formatNumberToText(value, decimals, ' cal')
      case 'category':
        return categories && categories[value] ? categories[value] : value
      default:
        return this.roundDecimals(value, decimals)
    }
  },

  // HELPERS
  roundDecimals (value, decimals = 2) {
    if (isNaN(value)) { value = Number(value) }
    return Math.round((value + Number.EPSILON) * Math.pow(10, decimals)) / Math.pow(10, decimals)
  },

  weekStartDate (referenceDate = new Date()) {
    let dateElem = new Date(referenceDate)
    dateElem = new Date(dateElem.setHours(0, 0, 0))
    const day = dateElem.getDay() || 7 // Get current day number, converting Sunday to 7
    if (day !== 1) { // Only manipulate the date if it isn't Monday
      dateElem.setHours(-24 * (day - 1)) // Set the hours to day number minus 1
    }
    return dateElem
  },

  weekEndDate (referenceDate = new Date()) {
    let dateElem = new Date(referenceDate)
    dateElem = new Date(dateElem.setHours(23, 59, 59))
    const day = dateElem.getDay() || 7 // Get current day number, converting Sunday to 7
    if (day !== 7) { // Only manipulate the date if it isn't Sunday
      dateElem.setHours(24 * (7 - day)) // Set the hours to difference for reaching sunday
    }
    return dateElem
  },

  monthStartDate (referenceDate = new Date()) {
    const dateElem = new Date(referenceDate)
    return new Date(dateElem.getFullYear(), dateElem.getMonth(), 1)
  },

  monthEndDate (referenceDate = new Date()) {
    const dateElem = new Date(referenceDate)
    return new Date(dateElem.getFullYear(), dateElem.getMonth() + 1, 0)
  },

  mergeObjects(target = {}, source = {}, setIfNull = true) {

    if (!target) { target = {} }
    if (isProxy(target)) { target = toRaw(target) }
    if (isProxy(source)) { source = toRaw(source) }

    for (const key in source) {

      // Is null
      if (source[key] === null) {
        target[key] = setIfNull ? source[key] : target[key]
      }

      // Is undefined
      else if (source[key] === undefined) {
        target[key] = target[key]
      }

      // Is string
      else if (typeof source[key] === 'string' || typeof target[key] === 'string') {
        target[key] = source[key]
      }

      // Is number
      else if (typeof source[key] === 'number' || typeof target[key] === 'number') {
        target[key] = source[key]
      }

      // Is array
      else if (Array.isArray(source[key])) {
        if (!Array.isArray(target[key])) { target[key] = [] }
        target[key] = target[key].concat(source[key])
      }
      
      // Is object
      else if (typeof source[key] === 'object') {
        if (typeof target[key] !== 'object') { target[key] = {} }
        target = { ...target, [key] : this.mergeObjects(target[key], source[key]) }
      }
      
      else {
        target[key] = source[key]
      }
    }

    return target
  },

  /* async mergeObjects (obj1, obj2 = {}) {
    return new Promise(async (resolve, reject) => {
        
      if (!obj1 && !obj2) { return resolve(null) }
      if (!obj1 && obj2) { return resolve(obj2) }
      if (obj1 && !obj2) { return resolve(obj1) }

      SWorker.run((obj1, obj2) => {

        const merged = {}
        for(const k in obj1 || {}) {
          if(merged[k] === undefined || merged[k] === null) { merged[k] = obj1[k] }
          if(obj1[k] === undefined) { continue }
          // Check if is string
          else if (typeof obj1[k] === 'string') { merged[k] = obj1[k] }
          // Check if object
          else if (typeof obj1[k] === 'object' && !Array.isArray(obj1[k])){ merged[k] = { ...(merged[k] || {}) , ...(obj1[k] || {}) } }
          // Check array
          else if (typeof obj1[k] === 'object' && Array.isArray(obj1[k])) { merged[k] = [...(merged[k] || []), ...(obj1[k] || [])] }
          // Other value
          else { merged[k] = obj1[k] }
        }
        for(const k in obj2 || {}) {
          if(merged[k] === undefined || merged[k] === null) { merged[k] = obj2[k] }
          if(obj2[k] === undefined) { continue }
          // Check if is string
          else if (typeof obj2[k] === 'string') { merged[k] = obj2[k] }
          // Check if object
          else if (typeof obj2[k] === 'object' && !Array.isArray(obj2[k])){ merged[k] = { ...(merged[k] || {}) , ...(obj2[k] || {}) } }
          // Check array
          else if (typeof obj2[k] === 'object' && Array.isArray(obj2[k])) { merged[k] = [...(merged[k] || []), ...(obj2[k] || [])] }
          // Other value
          else { merged[k] = obj2[k] }
        }

        return merged
      }, [obj1, obj2])
      .then(resolve)
      .catch(console.error) // logs any possible error

    })
  }, */

  getPrintOptions () {
    return {
      id: 'printable',
      extraCss: `/print.css`,
      previewTitle: 'JANBY Digital Kitchen - Cloud', 
      popTitle: 'JANBY Digital Kitchen - Cloud', 
    }
  },
}
