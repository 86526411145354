<template>
    <div id="app-sidenav" class="sidenav w-100 janby-title bg-janby">

        <slot></slot>

        <b-nav vertical align="left" class="mb-2 p-0 list-group">

          <!-- Home -->
            <b-nav-item to="/dashboard/" class="bg-janby" :active="isActive('/dashboard/')">
              <div class="d-flex">
                <i class="material-icons">home</i>
                <span v-show="layout.sidenav > 1">{{ $tc('m.Home') }}</span>
              </div>
            </b-nav-item>

          <!-- Organization -->
            <b-nav-item id="app-organization-button" v-if="canView('Organization')" class="bg-janby" to="/organization" :active="isActive('/organization')">
              <div class="d-flex">
                <i class="material-icons">business</i>
                <span v-show="layout.sidenav > 1">{{ $tc('m.Organization') }}</span>
              </div>
            </b-nav-item>
        </b-nav>

        <Explore :organization="organization" :location="location" :layout="layout" :permissions="permissions" :canView="canView" :canEdit="canEdit" :canCreate="canCreate" :isActive="isActive" :toggleVisible="toggleVisible" :visibles="visibles"/>

        <Location :organization="organization" :location="location" :layout="layout" :counts="counts" :permissions="permissions" :canView="canView" :canEdit="canEdit" :canCreate="canCreate" :isActive="isActive" :toggleVisible="toggleVisible" :visibles="visibles"/>

        <Reporting :organization="organization" :location="location" :layout="layout" :counts="counts" :permissions="permissions" :canView="canView" :canEdit="canEdit" :canCreate="canCreate" :isActive="isActive" :toggleVisible="toggleVisible" :visibles="visibles"/>

        <Production :organization="organization" :location="location" :layout="layout" :permissions="permissions" :canView="canView" :canEdit="canEdit" :canCreate="canCreate" :isActive="isActive" :toggleVisible="toggleVisible" :visibles="visibles"/>

        <Ordering :organization="organization" :location="location" :layout="layout" :permissions="permissions" :canView="canView" :canEdit="canEdit" :canCreate="canCreate" :isActive="isActive" :toggleVisible="toggleVisible" :visibles="visibles"/>

        <Stock :organization="organization" :location="location" :layout="layout" :permissions="permissions" :canView="canView" :canEdit="canEdit" :canCreate="canCreate" :isActive="isActive" :toggleVisible="toggleVisible" :visibles="visibles"/>

        <Planning :organization="organization" :location="location" :layout="layout" :permissions="permissions" :canView="canView" :canEdit="canEdit" :canCreate="canCreate" :isActive="isActive" :toggleVisible="toggleVisible" :visibles="visibles"/>
        
        <Haccp :organization="organization" :location="location" :layout="layout" :permissions="permissions" :canView="canView" :canEdit="canEdit" :canCreate="canCreate" :isActive="isActive" :toggleVisible="toggleVisible" :visibles="visibles"/>

        <Innovation :organization="organization" :location="location" :layout="layout" :permissions="permissions" :canView="canView" :canEdit="canEdit" :canCreate="canCreate" :isActive="isActive" :toggleVisible="toggleVisible" :visibles="visibles"/>

        <!-- <UserControl :organization="organization" :location="location" :layout="layout" :permissions="permissions" :canView="canView" :canEdit="canEdit" :canCreate="canCreate" :isActive="isActive" :toggleVisible="toggleVisible" :visibles="visibles"/> -->

        <!-- <ContainerMap :organization="organization" :location="location" :layout="layout" :permissions="permissions" :canView="canView" :canEdit="canEdit" :canCreate="canCreate" :isActive="isActive" :toggleVisible="toggleVisible" :visibles="visibles"/> -->

        <Monitor :organization="organization" :location="location" :layout="layout" :permissions="permissions" :canView="canView" :canEdit="canEdit" :canCreate="canCreate" :isActive="isActive" :toggleVisible="toggleVisible" :visibles="visibles"/>

        <Integration :organization="organization" :location="location" :layout="layout" :permissions="permissions" :canView="canView" :canEdit="canEdit" :canCreate="canCreate" :isActive="isActive" :toggleVisible="toggleVisible" :visibles="visibles"/>

        <!-- <ArtificialVision :organization="organization" :location="location" :layout="layout" :permissions="permissions" :canView="canView" :canEdit="canEdit" :canCreate="canCreate" :isActive="isActive" :toggleVisible="toggleVisible" :visibles="visibles"/> -->

        <EquipmentProvider :organization="organization" :location="location" :layout="layout" :permissions="permissions" :canView="canView" :canEdit="canEdit" :canCreate="canCreate" :isActive="isActive" :toggleVisible="toggleVisible" :visibles="visibles"/>

        <Affiliate :organization="organization" :location="location" :layout="layout" :permissions="permissions" :canView="canView" :canEdit="canEdit" :canCreate="canCreate" :isActive="isActive" :toggleVisible="toggleVisible" :visibles="visibles"/>

        <CRM :organization="organization" :location="location" :layout="layout" :permissions="permissions" :canView="canView" :canEdit="canEdit" :canCreate="canCreate" :isActive="isActive" :toggleVisible="toggleVisible" :visibles="visibles"/>

        <CloudAdmin :organization="organization" :location="location" :layout="layout" :permissions="permissions" :canView="canView" :canEdit="canEdit" :canCreate="canCreate" :isActive="isActive" :toggleVisible="toggleVisible" :visibles="visibles"/>

    </div>
</template>

<script>

import UserController from '@/controllers/base/user.controller.js'

import Explore from '@/views/components/_sidenav/Explore.vue'
import Location from '@/views/components/_sidenav/Location.vue'
import Monitor from '@/views/components/_sidenav/Monitor.vue'
import Production from '@/views/components/_sidenav/Production.vue'
import Stock from '@/views/components/_sidenav/Stock.vue'
import Planning from '@/views/components/_sidenav/Planning.vue'
import Haccp from '@/views/components/_sidenav/Haccp.vue'
import UserControl from '@/views/components/_sidenav/UserControl.vue'
import ContainerMap from '@/views/components/_sidenav/ContainerMap.vue'
import Reporting from '@/views/components/_sidenav/Reporting.vue'
import Innovation from '@/views/components/_sidenav/Innovation.vue'
// import ArtificialVision from '@/views/components/_sidenav/ArtificialVision.vue'
import Ordering from '@/views/components/_sidenav/Ordering.vue'
import EquipmentProvider from '@/views/components/_sidenav/EquipmentProvider.vue'
import Affiliate from '@/views/components/_sidenav/Affiliate.vue'
import CRM from '@/views/components/_sidenav/CRM.vue'
import Integration from '@/views/components/_sidenav/Integration.vue'
import CloudAdmin from '@/views/components/_sidenav/CloudAdmin.vue'

export default {
  props: {
    organization: {
      type: Object
    },
    location: {
      type: Object
    },
    permissions: {
      type: Object,
      default () { return {} }
    },
    counts: {
      type: Object,
      default () { return {} }
    },
    layout: {
      type: Object,
      default () { return { sidenav: 0 } }
    }
  },
  data () {
    return {
      visibles: JSON.parse(localStorage.getItem('visibleSidenavs')) || ['location']
    }
  },
  methods: {
    canView (modelName) {
      return UserController.canView(modelName, this.permissions)
    },
    canEdit (modelName) {
      return UserController.canEdit(modelName, this.permissions)
    },
    canCreate (modelName) {
      return UserController.canCreate(modelName, this.permissions)
    },
    isActive (routes, strict = false) {
      if (!routes.map) { // Turn into array
        routes = [routes]
      }
      return routes.map(r => strict ? this.$route.path === r : this.$route.path.indexOf(r) === 0).reduce((acc, cur) => acc || cur, false)
    },
    toggleVisible (sidenav, visible = null) {
      if (visible === true || this.visibles.indexOf(sidenav) < 0) {
        this.visibles.push(sidenav)
      } else {
        const idx = this.visibles.indexOf(sidenav)
        if (idx >= 0) { this.visibles.splice(idx, 1) }
      }
      localStorage.setItem('visibleSidenavs', JSON.stringify(this.visibles))
    }
  },
  components: {
    Explore,
    Location,
    Monitor,
    Production,
    Stock,
    Planning,
    Haccp,
    UserControl,
    ContainerMap,
    Reporting,
    Innovation,
    // ArtificialVision,
    Ordering,
    EquipmentProvider,
    Affiliate,
    CRM,
    Integration,
    CloudAdmin
  }
}
</script>

<style>

    .sidenav .list-group-item {
      border-radius: 0;
      padding: 0.75rem;
    }

    .sidenav .nav-item a {
      padding: 0.75rem;
      color: #fff;
    }

    .sidenav .nav-item .material-icons, .list-group-item :first-child {
      margin-right: 1rem;
    }

    .sidenav .nav-link {
      text-transform: unset;
      margin-right: 0;
    }

    .sidenav a:hover span {
        text-decoration: underline;
    }

    .sidenav a.active , .sidenav a.active:hover {
      background-color: var(--janby-color);
      color: #fff;
    }

    .sidenav .nav-item .icon-pill {
      font-weight: 500;
      background: transparent;
    }

    .sidenav .nav-link.active::before , .sidenav .nav-link:hover::before {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top:0;
      border-left: 8px solid var(--janby-color)
    }

    .sidenav .list-group-item:focus , .sidenav .list-group-item:hover {
      text-decoration: underline;
    }

    .sidenav .popover-body {
      padding: 0 !important;
      overflow: auto;
      max-height: 100vh;
    }

</style>
