export default {
  m: {
    hello: 'Hi Chef!!',
    goToEntity: 'Go to {entity} | Go to all {entity}',
    goBackToEntity: 'Go back to {entity} | Go back to all {entity}',
    about: 'About Janby | About Janby',
    blog: 'Last Janby news | Latest Janby news',
    logIn: 'Log in | Log in all',
    logInWithYourAccount: 'Log in with your account | Log in with your accounts',
    logOut: 'Log out | Log out from all',
    newEntityVersionFound: 'New {entity} version found | New {entity} versions found',
    giveYourFeedback: 'Give your feedback',
    ipAddress: 'IP address | IP addresses',
    wifiMacAddress: 'Wifi MAC address | Wifi MAC addresses',
    bluetoothMacAddress: 'Bluetooth MAC address | Bluetooth MAC addresses',
    macAddress: 'Ethernet MAC address | Ethernet MAC addresses',
    lastLogin: 'Logged in | Logged in',
    created: 'Created | Created',
    updated: 'Updated | Updated',
    closed: 'Closed | Closed',
    expiration: 'Expiration | Expirations',
    expiration_desc: 'Time after which the product is no longer safe for consumption',
    expirationWhenStarted: 'Expiration when action started | Expirations when action started',
    expirationWhenFinished: 'New expiration after action finished | New expirations after action finished',
    bestBefore: 'Best before | Best before',
    bestBefore_desc: 'Time after which the product is no longer in its perfect shape',
    email: 'E-mail | E-mails',
    image: 'Image | Images',
    phone: 'Phone number | Phone numbers',
    web: 'Website | Websites',
    url: 'Url | Urls',
    locale: 'Language | Languages',
    timeZone: 'Time zone | Time zones',
    password: 'Password | Passwords',
    forgotPassword: 'Forgot password?',
    recoverPassword: 'Recover password',
    noRepeat: 'Do not repeat | Do not repeat',
    title: 'Title | Titles',
    text: 'Text | Texts',
    body: 'Body | Bodies',
    table: 'Table | Tables',
    chart: 'Chart | Charts',
    id: 'Identifier | Identifiers',
    name: 'Name | Names',
    lastName: 'Last name | Last names',
    publicName: 'Tradename | Tradenames',
    publicName_desc: 'Tradename to be shown in public profile page and printed labels',
    publicProfile: 'Public profile | Public profiles',
    publicDescription: 'Public description | Public descriptions',
    publicDescription_desc: 'Description other users are able to see in the organization\'s public profile',
    publicEntity: 'Public {entity} | Public {entity}',
    code: 'Code | Codes',
    icon: 'Icon | Icons',
    geometry: 'Geometry | Geometries',
    description: 'Description | Descriptions',
    entityDescription: '{entity} description | {entity} descriptions',
    entityWeight: '{entity} weight | {entity} weights',
    tag: 'Tag | Tags',
    tag_desc: 'Tags are words to help you categorize any element. You can add multiple tags separated by commas and use them to filter the list of elements',
    color: 'Color | Colors',
    type: 'Type | Types',
    note: 'Additional note | Additional notes',
    active: 'Active | Actives',
    active_desc: 'Deactivated elements will be hidden',
    available: 'Available | Availables',
    completed: 'Completed | Completed',
    deleted: 'Deleted | Deleted',
    tester: 'Tester | Testers',
    canceled: 'Canceled | Canceled',
    pending_cancel: 'Pending cancel | Pending cancel',
    processing: 'Processing | Processeing',
    failed: 'Failed | Faileds',
    refunded: 'Refunded | Refunded',
    onHold: 'On hold | On hold',
    pending: 'Pending | Pending',
    enabled: 'Enabled | All enabled',
    disabled: 'Disabled | All disabled',
    previous: 'Previous | Previous all',
    next: 'Next | Next',
    skip: 'Skip | Skip',
    remindLater: 'Remind later | Remind all later',
    finish: 'Finish | Finish all',
    price: 'Price | Prices',
    cost: 'Cost | Costs',
    income: 'Income | Incomes',
    date: 'Date | Dates',
    datetime: 'Date and time | Dates and times',
    column: 'Column | Columns',
    execution: 'Execution | Executions',
    waitTime: 'Waiting time | Waiting times',
    waitTimeCurrentStart: 'Startup delay time | Startup delay times',
    waitTimeCurrentEnd: 'Extraction delay time | Extraction delay times',
    day: 'Day | Days',
    day_0: 'Sunday | Sundays',
    day_1: 'Monday | Mondays',
    day_2: 'Tuesday | Tuesdays',
    day_3: 'Wednesday | Wednesdays',
    day_4: 'Thursday | Thursdays',
    day_5: 'Friday | Fridays',
    day_6: 'Saturday | Saturdays',
    second: 'Second | Seconds',
    minute: 'Minute | Minutes',
    hour: 'Hour | Hours',
    week: 'Week | Weeks',
    month: 'Month | Months',
    range: 'Range | Ranges',
    today: 'Today | Today',
    yesterday: 'Yesterday | Yesterday',
    currentEntity: 'Current {entity} | Current {entity}',
    lastEntity: 'Last {entity} | Last {entity}',
    targetEntity: 'Target {entity} | Target {entity}',
    from: 'From | From',
    to: 'To | To',
    file: 'File | Files',
    rating: 'Rating | Ratings',
    total: 'Total | Total',
    amount: 'Amount | Amounts',
    entityAmount: '{entity} amount | {entity} amounts',
    expectedCount: 'Expected product amount | Expected product amount',
    registeredCount: 'Registered product amount | Registered product amount',
    version: 'Version | Versions',
    version_desc: 'When synchronizing devices and cloud, the version with the highest number will be used as most recent.',
    channel: 'Channel | Channels',
    priority: 'Priority in list | Priorities in list',
    priority_desc: 'Order in which the element will be shown in lists in devices',
    expired: 'Expired | Expired',
    notExpired: 'Not expired | Not expired',
    register: 'Register | Register all',
    registerEntity: 'Register {entity} | Register {entity}',
    all: 'All | All',
    allEntity: 'All {entity} | All {entity}',
    never: 'Never | Never',
    save: 'Save | Save',
    saveEntity: 'Save {entity} | Save {entity}',
    create: 'Create | Create',
    createEntity: 'Create {entity} | Create {entity}',
    transferEntity: 'Transfer {entity} | Transfer {entity}',
    updateEntity: 'Update {entity} | Update {entity}',
    run: 'Run | Run',
    runEntity: 'Run {entity} | Run {entity}',
    savedEntity: 'Saved {entity} | Saved {entity}',
    scheduledEntity: 'Scheduled {entity} | Scheduled {entity}',
    search: 'Search | Search',
    searchEntity: 'Search {entity} | Search {entity}',
    searchInHistory: 'Search in history | Search in history',
    reloadEntity: 'Reload {entity} | Reload {entity}',
    entityEnabled: '{entity} enabled | {entity} enabled',
    entityDisabled: '{entity} disabled | {entity} disabled',
    enableEntity: 'Enable {entity} | Enable {entity}',
    disableEntity: 'Disable {entity} | Disable {entity}',
    useExistingEntity: 'Use existing {entity} | Use existing {entity}',
    countAvailableEntity: '{count} available {entity} | {count} available {entity}',
    delete: 'Delete | Delete',
    remove: 'Remove | Remove',
    select: 'Select | Select',
    selectEntity: 'Select {entity} | Select {entity}',
    selectEntityType: 'Select {entity} type | Select {entity} types',
    entityEntity: '{entity1} {entity2}',
    add: 'Add | Add',
    addEntity: 'Add {entity} | Add {entity}',
    edit: 'Edit | Edit',
    editEntity: 'Edit {entity} | Edit {entity}',
    view: 'View | View',
    viewEntity: 'View {entity} | View {entity}',
    copy: 'Copy | Copy',
    copyEntity: 'Copy {entity} | Copy {entity}',
    sendEntity: 'Send {entity} | Send {entity}',
    removeEntity: 'Remove {entity} | Remove {entity}',
    deleteEntity: 'Delete {entity} | Delete {entity}',
    cloneEntity: 'Clone {entity} | Clone {entity}',
    moreEntity: 'More {entity} | More {entity}',
    import: 'Import | Import',
    importEntity: 'Import {entity} | Import {entity}',
    export: 'Export | Export',
    exportEntity: 'Export {entity} | Export {entity}',
    detail: 'Details | Details',
    entityDetail: '{entity} detail | {entity} details',
    settings: 'Settings | Settings',
    liveEntity: 'Live {entity} | Live {entity}',
    size: 'Size | Sizes',
    download: 'Download | Download',
    upload: 'Upload | Upload',
    uploadEntity: 'Upload {entity} | Upload {entity}',
    connect: 'Connect | Connect',
    loading: 'Loading | Loading',
    loadingEntity: 'Loading {entity} | Loading {entity}',
    setAsStatus: 'Set as {status} | Set as {status}',
    setEntityAsStatus: 'Set {entity} as {status} | Set {entity} as {status}',
    print: 'Print | Print',
    printEntity: 'Print {entity} | Print {entity}',
    groupEntity: 'Group {entity} | Group {entity}',
    close: 'Close | Close',
    cancel: 'Cancel | Cancel',
    clear: 'Clear | Clear selection',
    option: 'Option | Options',
    result: 'Result | Results',
    preview: 'Preview | Previews',
    barcode: 'Barcode | Barcodes',
    bytecode: 'Janby QR | Janby QRs',
    meta: 'Custom property | Custom properties',
    start: 'Start | Starts',
    ready: 'Ready | Ready',
    end: 'End | Ends',
    filter: 'Filter | Filters',
    filterByEntity: 'Filter by {entity} | Filter by {entity}',
    noFilter: 'No filter applied | No filters applied',
    required: 'Required | Required',
    notRequired: 'Not required | Not required',
    locked: 'Locked | Lockeds',
    public: 'Public | Publics',
    private: 'Private | Privates',
    private_desc: 'Only visible for organization users in the cloud | Only visible for organization users in the cloud',
    maximum: 'Maximum | Maximums',
    minimum: 'Minimum | Minimums',
    noData: 'No data | No data',
    unknown: 'Unknown | Unknown',
    default: 'Default | Defaults',
    none: 'None | None',
    noEntityFound: 'No {entity} found | No {entity} found',
    notAllowed: 'Not allowed | Not allowed',
    youSure: 'Are you sure? | Are you sure?',
    clickToLoad: 'Click to load | Click to load',
    syncEntity: 'Sync {entity} | Sync {entity}',
    syncrhonizedEntity: 'Syncrhonized {entity} | Syncrhonized {entity}',
    mergeEntity: 'Combine {entity} | Combine {entity}',
    mergeIntoEntity: 'Combine the result into {entity} | Combine the results into {entity}',
    publicData: 'Public information | Public information',
    publicData_desc: 'Visible in the public profile and labels of the organization/produc/device etc. | Visible in the public profile and labels of the organization/produc/device etc.',
    privateData: 'Private information | Private information',
    privateData_desc: 'Only visible for this organization users',

    // Home
    Home: 'Home page',
    Dashboard: 'Dashboard | Dashboards',
    Summary: 'Summary | Summaries',
    Cooking: 'Cooking | Cookings',
    home_tour_0: 'Welcome to Janby Cloud!<br/><br/>Let us tour you on how to manage your location and profile.',
    home_tour_1: 'This is location you are currently logged in.<br/><br/>Users, Devices, Products, etc. are grouped into locations so users will only see elements belonging to their same location.',
    home_tour_2: 'You can switch between any of your available locations or go to see the full list.<br/><br/>When switching, lists will be reloaded to show the elements belonging to the new location.',
    home_tour_3: 'You can edit your use profile and change the permissions per every location.',

    // Organization
    Organization: 'Organization | Organizations',
    organizationConnectionId: 'Organization connection ID | Organization connection IDs',
    organizationConnectionId_desc: 'Code required to connect your devices to Janby Cloud. This code is unique for your entire organization and will be requested by your devices the first time you configure them.',
    isViewFull: 'Full cloud view',
    isViewFull_desc: 'Show data of all organization, regardless of the logged in location',
    isViewFullEnabled_desc: 'Viewing full cloud data',
    isViewFullDisabled_desc: 'Data from current location ({entity}) and sublocations',
    contact_organization_manager: 'Contact your organization manager',
    organization_tour_0: 'Edit your organization details.<br/><br/>Public data will be visible for other organizations you may provide/receive products to/from.',
    organization_tour_1: 'Structure your organization locations.<br/><br/>Drag and drop locations to create hierarchies for users, products and devices.',
    organization_tour_2: 'All the licenses your organization is subscribed to.<br/><br/>You can see information regarding expiration, next payments and which user/device is using it.',
    locationStructure: 'Loations structure | Locations structures',
    locationStructureClickDrag: 'Drag locations to modify the structure or click a location to log in it',
    geom: 'Geometry | Geometries',
    geomMap: 'Geometry map | Geometry maps',
    entityMap: '{entity} map | {entity} maps',
    entityList: '{entity} list | {entity} lists',
    organizationRecommendeds: "Organization's products that may be of interest to you.",

    // Business areas
    'janby/business/restaurant': 'Restaurant | Restaurants',
    'janby/business/bar': 'Bar/Pub/Cafe | Bar/Pub/Cafes',
    'janby/business/colective': 'Colectivity | Colectivities',
    'janby/business/hotel': 'Hotel | Hotels',
    'janby/business/delivery': 'Delivery service | Delivery services',
    'janby/business/online': 'Online Sales | Online Sales',
    'janby/business/retail': 'Retail/Supermarket/Catering | Retail/Supermarket/Caterings',
    'janby/business/dealer': 'Foodservice dealer | Foodservice dealers',
    'janby/business/provider': 'Kitchen equipment provider | Kitchen equipment providers',
    'janby/business/technical': 'Technical Service | Technical Services',
    'janby/business/consultant': 'Consultant | Consultants',
    'janby/business': 'Other business | Other businesses',

    // License
    AuthLicense: 'Llicense | Licenses',
    License: 'License | Licenses',
    entityLicense: 'License in {entity} | Licenses in {entity}',
    licenseForEntity: 'License for {entity} | Licenses for {entity}',
    'use/total': 'Use/Total',
    Subscription: 'Subscription | Subscriptions',
    lastPayment: 'Last Payment | Last Payments',
    nextPayment: 'Next Payment | Next Payments',
    permission_0_desc: 'Can not see {entity}',
    permission_1_desc: 'See but not edit/create/destroy {entity}',
    permission_2_desc: 'See and edit but not create/destroy {entity}',
    permission_3_desc: 'Edit and create but no destroy {entity}',
    permission_4_desc: 'Edit, create and destroy {entity}',
    // Licenses user
    'janby/license/user/backup': 'Backup for devices and programs',
    'janby/license/user/history/90': 'Last 3 months of cooking data',
    'janby/license/user/history/180': 'Last 6 months of cooking data',
    'janby/license/user/history/unlimited': 'Unlimited history of cooking data',
    'janby/license/user/report': 'Reporting user license',
    'janby/license/user/chef': 'Chef user license',
    'janby/license/user/equipment': 'Cooking equipment provider',
    'janby/license/user/label/ready-to-eat': 'Ready-To-Eat food producers',
    'janby/license/user/label/writer': 'Label producer for self consumption',
    'janby/license/user/label/reader': 'Consumed labels view',
    'janby/license/user/order/reader': 'Order connect',
    'janby/license/user/affiliate/organization': 'Affiliated organizations',
    'janby/license/user/affiliate/user': 'Affiliated organization users',
    'janby/license/user/affiliate/device': 'Affiliated organization devices',
    'janby/license/user/affiliate/license': 'Affiliated organization licenses',
    'janby/license/user/gstock': 'G-Stock connector',
    'janby/license/user/apicbase': 'Apicbase connector',
    // Licenses device
    'janby/license/device/track/mini': 'Track Mini',
    'janby/license/device/track/core': 'Track Core',
    'janby/license/device/track/pro': 'Track Pro',
    'janby/license/device/track/intelligence': 'Track Intelligence',
    'janby/license/device/track/ordering': 'Track Order-Connect',
    'janby/license/device/track/unlimited': 'Track Unlimited',

    'sammic/license/device/smartvide/7': 'Sammic SmartVide 7',
    'sammic/license/device/smartvide/x': 'Sammic SmartVide X',

    'janby/license/device/test/core': 'Test Core',

    'janby/license/device/pick/core': 'Pick Core',

    'janby/license/device/checkpoint/core': 'Checkpoint Core',

    // Action
    Action: 'Action | Actions',
    cookTime: 'Cooking time | Cooking times',
    cookTime_desc: 'Duration the device timer will monitor',
    cookTimeIntermediateNotifications: 'Intermediate warnings during process',
    progressTime: 'Progress time | Progress times',
    progressTime_desc: 'Progress time | Progress times',
    holdTime: 'Holding time | Holding times',
    holdTime_desc: 'Time the product will be awaiting ready until the operator extracts it',
    maxHoldTime: 'Maximum holding time',
    maxCookTime: 'Maximum cooking/chilling time',
    waitingToStart: 'Wait timer until',
    waitingToStart_desc: 'Process timer will wait until the conditions are met',
    retainedCooking: 'Can be held cooking until',
    retainedCooking_desc: 'Process will not be ready until the conditions are met',
    readyToUse: 'Ready to use',
    readyToUse_desc: 'Process finished and waiting for operator to extract the product',
    untilProcessControl: 'Until process control fulfilled',
    targetCookTime: 'Target cook time | Target cook times',
    cookTemperature: 'Cook temperature | Cook temperatures',
    bathTemperature: 'Bath temperature | Bath temperatures',
    targetCookTemperature: 'Target cook temperature | Target cook temperatures',
    targetTemperature: 'Target temperature | Target temperatures',
    sensorValue: 'Sensor value | Sensor values',
    targetValue: 'Target value | Target values',
    verifiedValue: 'Verified value | Verified values',
    appliedOffset: 'Applied offset | Applied offsets',
    probeRequiredFinalTemperature: 'Expected probe temperature | Expected probe temperatures',
    probeRegisteredFinalTemperature: 'Probe final temperature control | Probe final temperature controls',
    minimumStockValuesControl: 'Hot-Hold amounts (par levels) control | Hot-Hold amounts (par levels) controls',
    pasteurizationControl: 'Lethality/Pasteurization control | Lethality/Pasteurizations controls',
    stabilizationControl: 'Stabilization control | Stabilization controls',
    pasteurized: 'Pasteurized | Pasteurized',
    'janby/action/label/generation': 'Label registration in Cloud | Labels registration in Cloud',
    'janby/action/label/scan': 'Label scanned by device | Labels scanned by device',
    'janby/action/label/printing': 'Printed label | Printed labels',
    'janby/action/label/printing/cooking': 'Printed label with elaboration | Printed labels with elaboration',
    'janby/action/label/printing/identification': 'Printed identificative label | Printed identificative labels',
    'sammic/action/smartvide/cooking': 'SousVide Cooking with Sammic SmartVide | SousVide Cooking with Sammic SmartVide',
    'sammic/action/smartvide/x/timing': 'Retherming Timer for Sammic SmartVide X | Retherming timers for Sammic SmartVide X',
    'sammic/action/smartvide/calibration': 'Sammic Smartvide Calibration | Sammic Smartvide Calibrations',
    'sammic/action/smartvide/calibration/manual': 'Sammic Smartvide Manual Calibration | Sammic Smartvide Manual Calibrations',
    'sammic/action/smartvide/calibration/ice': 'Sammic Smartvide Calibration using ice | Sammic Smartvide Calibrations using ice',
    'sammic/action/vacuum/sealing': 'Vacuum sealing | Vacuum sealings',
    'sammic/action/vacuum/sealing/staged': 'Staged vacuum sealing | Staged vacuum sealings',
    'sammic/action/vacuum/sealing/vacnorm': 'Vacnorm vacuum sealing | Vacnorm vacuum sealings',
    'sammic/action/vacuum/oil/drying': 'Oil drying | Oil dryings',
    'sammic/action/blastchiller/freeze/timing': 'Sammic Blast freezing timer | Sammic Blast freezing timers',
    'janby/action/track/timing': 'Janby Track timer | Janby Track timers',
    'janby/action/nop': 'Operation without time | Operations without time',
    'janby/action/pick/timing': 'Pick timer | Pick timers',
    'janby/action/track/probe/monitoring': 'Heating until Core target temperature with Probe and Janby Track | Heating until Core target temperature with Probe and Janby Track',
    'janby/action/track/probe/chilling': 'Chilling with Probe and Janby Track | Chilling with Probe and Janby Track',
    'janby/action/track/virtual/temperature/cooking': 'Janbyfied temperature program | Janbyfied temperature programs',
    'janby/action/fridge/store/start': 'Fridge storage | Fridge storage',
    'janby/action/fridge/store/end': 'Fridge storage end | Fridge storage ends',
    'janby/action/freezer/store/start': 'Freezer storage | Freezer storage',
    'janby/action/freezer/store/end': 'Freezer storage end | Freezer storage ends',
    'janby/action/stock/receive': 'Stock reception | Stock receptions',
    'janby/action/stock/send': 'Stock sending | Stock sendings',
    'janby/action/stock/transfer': 'Stock transfer | Stock transfers',
    'janby/action/stock/variation': 'Stock variation | Stock variations',
    'janby/action/serve': 'Product served | Products served',
    'janby/action/consume': 'Consume/Serve | Consume/Serve',
    'janby/action/reuse': 'Extract to be reused | Extract to be reused',
    'janby/action/waste': 'Waste product | Waste products',
    'janby/action/cancel': 'Cancel product | Cancel products',
    'janby/action/media': 'Element media | Element medias',
    'janby/action/transition': 'Element data change | Elements data change',
    'janby/action/container/content/register': 'Add to container | Add to containers',
    'janby/action/container/content/delete': 'Remove from container | Remove from containers',

    // Global Alerts
    GlobalAlert: 'Global alert | Global alerts',

    // Elaboration state
    ElaborationState: 'Elaboration state | Elaboration states',
    publicState_desc: 'Generic elaboration state, can not be deleted nor modified. | Generic elaboration states, can not be deleted nor modified',
    'janby/state/init': 'Starting state | Starting states',
    'janby/state/any': 'Any state | Any states',
    'janby/state/prepare': 'Prepared | Prepared',
    'janby/state/label': 'Labelled | Labelled',
    'janby/state/cook': 'Cooked | Cooked',
    'janby/state/chill': 'Chilled | Chilled',
    'janby/state/freeze': 'Frozen | Frozen',
    'janby/state/defrost': 'Defrost | Defrost',
    'janby/state/thaw': 'Thawed | Thawed',
    'janby/state/ready': 'Ready | Ready',
    'janby/state/serve': 'Finished | Finished',
    'janby/state/end': 'Finished | Finished',
    'janby/state/consume': 'Consumed | Consumed',
    'janby/state/waste': 'Wasted | Wasted',
    'janby/state/reuse': 'Reusable | Reusable',
    'janby/state/vacuumseal': 'Vacuum sealed | Vacuum sealed',
    'janby/state/sear': 'Seared | Seared',
    'janby/state/broil': 'Broiled | Broiled',
    'janby/state/retherm': 'Rethermalized | Rethermalized',
    'janby/state/cancel': 'Canceled | Canceled',
    'janby/state/unknown': 'Unknown | Unknown',

    // Location
    Location: 'Location | Locations',
    locationEntity: 'Location {entity} | Locations {entity}',
    parentLocation: 'Location parent | Locations parents',
    leaveLocation: 'Leave current location',
    changeToLocation: 'Change to this location',
    noLocationAllowed: 'No location allowed',
    enrolledEntity: 'Enrolled {entity} | Enrolled {entity}',
    enrolledEntityAt: 'Enrolled {entity} at {entity} | Enrolled {entity} at {entity}',
    childLocation: 'Child location | Child locations',
    childLocation_desc: 'Locations that are within "{name}"',
    addChildLocation_desc: 'Add a child location to "{name}"',
    'janby/location/': 'Location | Locations',
    'janby/location/brand/': 'Brand | Brands',
    'janby/location/division/': 'Division | Divisions',
    'janby/location/group/': 'Location group | Location groups',
    'janby/location/production/': 'Production center | Productions center',
    'janby/location/selling/': 'Selling Point | Selling Points',
    'janby/location/kitchen/': 'Kitchen | Kitchens',
    'janby/location/warehouse/': 'Warehouse | Warehouses',
    'janby/ubication/': 'Ubication | Ubications',
    'janby/ubication/room/': 'Room | Rooms',
    'janby/ubication/shelf/': 'Shelf | Shelves',
    'janby/ubication/table/': 'Table | Tables',
    'janby/ubication/seat/': 'Seat | Seats',
    'janby/ubication/position/': 'Position | Positions',
    country: 'Country | Countries',
    region: 'Region | Regions',
    city: 'City | Cities',
    address: 'Address | Addresses',
    postcode: 'Post code | Post codes',
    businessArea: 'Business area | Business areas',
    vatId: 'VAT ID | VAT IDs',
    rgseaa: 'RGSEAA number | RGSEAA numbers',
    rgseaa_desc: 'Number at the Spanish Registry of food and companies. <a href="https://www.aesan.gob.es/AECOSAN/web/seguridad_alimentaria/seccion/registro.htm" target="blank">More info</a>',

    // User
    User: 'User | Users',
    Me: 'Me | Me',
    userProfile: 'Profile and Settings',
    roleLevel: 'Cloud Role | Cloud Roles',
    roleLevel_desc: 'Role of the user in JANBY Cloud. The role is a global attribute of the user that applies in all locations. Subsequently, the user can have different licenses activated for each location. The combination of the role together with the activated licenses determines the permissions the user has in each location.',
    'janby/role/owner': 'Owner | Owners',
    'janby/role/admin': 'Administrator | Administrators',
    'janby/role/corporate': 'Corporate | Corporates',
    'janby/role/executive': 'Executive | Executives',
    'janby/role/manager': 'Location manager | Location managers',
    'janby/role/user': 'Employee | Employees',
    userLogins: 'Session history | Sessions history',
    changeEmailAndPassword: 'Change email and password',
    needHelp: 'Need help?',
    contactUs: 'Contact us',
    aroundTheWorld: 'JANBY around the world',
    findOurProducts: 'Where to get our products',
    profile_tour_0: 'Edit your profile information and settings',
    profile_tour_1: 'Change your e-mail and password for login into Janby Cloud.',
    profile_tour_2: 'Activate/deactivate your licenses in this location.<br/><br/>These will change the actions you are allowed to perform in this location (and child locations).',
    profile_tour_3: 'Enroll your user to the locations it must have acces to.<br/><br/>When having more than one location, you can enroll users to access as many locations as required.',
    'janby/user/company/owner': 'Owner | Owners',
    'janby/user/company/admin': 'Administrator | Administrators',
    'janby/user/company/manager': 'Manager | Managers',
    'janby/user/company/operations': 'Operations | Operations',
    'janby/user/company/operations/manager': 'Operations manager | Operations managers',
    'janby/user/company/sales': 'Sales | Sales',
    'janby/user/company/sales/manager': 'Sales manager | Sales managers',
    'janby/user/company/purchases': 'Purchases | Purchases',
    'janby/user/company/purchases/manager': 'Purchases manager | Purchases managers',
    'janby/user/company/marketing': 'Marketing | Marketing',
    'janby/user/company/marketing/manager': 'Marketing manager | Marketing managers',
    'janby/user/company/technology': 'Technology | Technology',
    'janby/user/company/technology/manager': 'Technology manager | Technology managers',
    'janby/user/company/finances': 'Finances | Finances',
    'janby/user/company/finances/manager': 'Finances manager | Finances managers',
    'janby/user/company/advice': 'Adviser | Advisers',
    'janby/user/company/advice/manager': 'Adviser manager | Adviser managers',
    'janby/user/company/chef': 'Chef | Chefs',
    'janby/user/company/chef/corporate': 'Corporate Chef | Corporate Chefs',
    'janby/user/company/operator': 'Operator | Operators',
    'janby/user/external': 'External | Externals',
    'janby/user/external/colaborator': 'External colaborator | External colaborators',
    'janby/user/external/contact': 'External contact | External contacts',

    // Permission description
    permission: 'Permission | Permissions',
    permission_desc: 'Permissions allow users to view elements and perform actions in a location. They are calculated according to the user role (Employee, Manager, Executive, Corporate or Administrator) and licenses it has active in the current location.',
    'janby/role/owner_desc': 'Can view all organization and modify any element',
    'janby/role/admin_desc': 'Can view all organization and modify any element',
    'janby/role/corporate_desc': 'Can create, view and edit elements but only in its enrolled locations',
    'janby/role/executive_desc': 'Can create, view and edit elements but only in its enrolled locations',
    'janby/role/manager_desc': 'Can view and edit elements but only in its enrolled locations',
    'janby/role/user_desc': 'Can only view elements in its enrolled locations',
    permissionFromParentLocation: 'License assigned in parent location | Licenses assigned in parent location',
    allLicensesInUse: 'All licenses in use',
    buyMoreEntity: 'Buy more {entity} | Buy more {entity}',

    // Device
    Device: 'Device | Devices',
    isBought: 'Bought',
    isRented: 'Rented',
    deviceLogins: 'Session history | Sessions history',
    serialNumber: 'Serial number | Serial numbers',
    activationKey: 'Activation key | Activation keys',
    softwareVersion: 'Software version | Software versions',
    hardwareVersion: 'Hardware version | Hardware versions',
    install: 'Install',
    uninstall: 'Uninstall',

    // Menu
    Menu: 'Menu | Menus',
    MenuCourse: 'Menu course | Menu courses',

    // Product
    Product: 'Product | Products',
    'janby/product/': 'Product | Products',
    'janby/product/selling/': 'Selling product | Selling products',
    'janby/product/selling': 'Selling product | Selling products', // TO BE DELETED
    'janby/product/semi/': 'Intermediate product | Intermediate products',
    'janby/product/semi': 'Intermediate product | Intermediate products',  // TO BE DELETED
    'janby/product/ingredient/': 'Ingredient | Ingredients',
    'janby/product/ingredient': 'Ingredient | Ingredients',  // TO BE DELETED
    product_tour_0: 'Edit the product general information.',
    product_tour_1: 'Set the product allergens.<br/><br/>These can be classified into 4 groups according to how sure we are of their presence.',
    product_tour_2: 'Design the elaboration route for the product.<br/><br/>Select the programs that must be used and set the states the product goes through at each step.',
    product_tour_3: 'Enable printing labels for this product.<br/><br/>Additionaly define the formats in which your product is supplied/labeled and their details.',
    product_tour_4: 'Set the locations where the product is used.<br/><br/>Only devices enrolled in the same locations as the product will be able to print labels for it.',
    sku: 'SKU | SKUs',
    gtin: 'GTIN | GTINs',
    gtin_desc: 'GTIN-14 code for the product',
    production: 'Production | Productions',
    produceBatchOf: 'Produce a batch of | Produce batches of',
    productionSheet: 'Production sheet | Production sheets',
    consumption: 'Consumption | Consumptions',
    consumptionUnit: 'Consumption unit | Consumption units',
    consumptionUnit_desc: 'Set how this product is portioned/consumed: single units, weight, slices, leaves...',
    productOrigin: 'Product origin | Product origins',
    Producer: 'Producer | Producers',
    produced: 'Production date | Production dates',
    Elaboration: 'Elaboration | Elaborations',
    ElaborationRoute: 'Elaboration route | Elaboration routes',
    ElaborationStep: 'Elaboration step | Elaboration steps',
    noElaborationRoute: 'Product with no elaboration steps | Products have no elaboration steps',
    isPrintableInIndividualQr: 'Print this elaboration step in Individual Label QR | Print these elaboration steps in Individual Label QR',
    isPrintableInRangeQr: 'Print this elaboration step in Batch/Range QR | Print these elaboration steps in Batch/Range QR',
    isPrintableInQr_desc: 'If scanning QRs inside your organization, your devices will already know the elaboration steps and it is not needed to print the elaboration steps inside the QR. However if you plan to send these QRs to other organizations, select which elaboration steps must be printed inside the QR so they can be executed by other devices.',
    isPrintableInProductionSheet: 'Print this elaboration step in Production Sheet | Print these elaboration steps in Production Sheet',
    newPagePerStep: 'Start new page per step',
    ProductComposition: 'Subproduct tree | Subproducts tree',
    ProductModifier: 'Product modifier | Product modifiers',
    ProductBatching: 'Packaging/labeling format | Packaging/labeling formats',
    batchingName: 'Packaging/labeling name | Packaging/labeling names',
    batchingName_desc: 'Name of the packaging/labeling format to be able to differentiate them',
    batchingWeight: 'Package weight | Packages weight',
    nextBatchSerial: 'Next batch serial | Next batch serials',
    nextBatchSerial_desc: 'Next serial number to be assigned to the next generated batch of this product',
    printByDevice: 'Send labels to this device | Send labels to these devices',
    printByDevice_desc: 'Send labels to this device for it to print them, the device must have Cloud connection active and the printer connected to it',
    nextProductSerial: 'Next product serial | Next product serials',
    nextProductSerial_desc: 'Next serial number to be assigned to the next generated label of this product',
    nextBatchId: 'Next batch ID for this labeling format | Next batch IDs for this labeling format',
    productStocking: 'Manage stock | Manage stock',
    Allergen: 'Allergen | Allergens',
    containsAllergen: 'Contained allergen | Contained allergens',
    tracesAllergen: 'Can have traces of | Can have traces of',
    cantEnsureAllergen: 'Cannot ensure if contains | Cannot ensure if contains',
    notContainsAllergen: 'Not contained | Not contained',
    'janby/allergen/celery/': 'Celery | Celery',
    'janby/allergen/cereal/': 'Cereal | Cereals',
    'janby/allergen/crustaceans/': 'Crustacean | Crustaceans',
    'janby/allergen/eggs/': 'Egg | Eggs',
    'janby/allergen/fish/': 'Fish | Fish',
    'janby/allergen/gluten/': 'Gluten | Gluten',
    'janby/allergen/lupin/': 'Lupin | Lupin',
    'janby/allergen/milk/': 'Dairy milk | Dairy milk',
    'janby/allergen/molluscs/': 'Mollusc | Molluscs',
    'janby/allergen/mustard/': 'Mustard | Mustard',
    'janby/allergen/nuts/': 'Tree Nut | Tree Nuts',
    'janby/allergen/peanuts/': 'Peanut | Peanuts',
    'janby/allergen/sesame/': 'Sesame | Sesame',
    'janby/allergen/so2/': 'Sulfur dioxide and sulfite | Sulfur dioxide and sulfites',
    'janby/allergen/soya/': 'Soybean | Soybeans',
    allergensNotDefined: 'Allergens not defined for this product',
    Recommended: 'Recommended  | Recommendeds',
    Diets: 'Suitable for diet | Suitable for diets',
    diet:{
      vegetarian: 'Vegetarian',
      vegan: 'Vegan',
      halal: 'Halal',
      kisher: 'Kosher',
      coelian: 'Celiac',
      lactose: 'Lactose intolerant',
      blackened: 'Blackened'
    },

    // Program
    Program: 'Elaboration program | Elaboration programs',
    ProductProgram: 'Product/Program | Products/Programs',
    cookingConfiguration: 'Program parameter | Program parameters',
    ActiveControl: 'Active control | Active controls',
    lockEntity: 'Lock {entity} - Prevent being changed by any device',
    programType_desc: 'The type of a program can not be changed once created. If required, you will need to delete the program and create a new one.',
    programProduct_desc: 'Products with an elaboration route that contains this program.',
    fromState: 'Product state prior to program | Products states prior to program',
    fromState_desc: 'The elaboration state (from the elaboration route) the product is in, before starting the program.',
    nextState: 'Product state, after correct program end | Products states, after correct program end',
    nextState_desc: 'The elaboration state (from the elaboration route) the product will be in after correctly finishing the program.',
    reuseState: 'Product state, if product is extracted to be reused | Product states, if products are extracted to be reused',
    reuseState_desc: 'The elaboration state (from the elaboration route) the product will go back to if the operator extracts the product to be reused.',
    nextBestBeforeTime: 'Best before time when correctly ended',
    nextBestBeforeTime_desc: 'Optionally set a best before time to the product once the program has finished correctly',
    nextExpirationTime: 'Expiration when correctly ended',
    nextExpirationTime_desc: 'Optionally set an expiration to the product once the program has finished correctly',
    reuseBestBeforeTime: 'Best before time when finished to be reused',
    reuseBestBeforeTime_desc: 'Optionally set a best before time to the product if the operator extracts the product to be reused reused',
    reuseExpirationTime: 'Expiration when finished to be reused',
    reuseExpirationTime_desc: 'Optionally set an expiration to the product if the operator extracts the product to be reused reused',
    isConsumable: 'Once program is correctly ended, product can be consumed | Once program is correctly ended, products can be consumed',
    isConsumable_desc: 'If activated, the product will be able to additionally be served/consumed once this step has ended correctly. If not, the product will be set to the state defined in "Product state, after correct program end".',

    // Container
    Container: 'Container | Containers',
    ContainerShape: 'Container format | Container formats',
    ContainerContent: 'Content | Content',
    containerMacAddress: 'MAC address or ID | MAC addresses or IDs',
    containerMacAddress_desc: 'Internal MAC address of the RFID or ID of the container',
    sscc: 'SSCC | SSCCs',

    // Period
    period: 'Period | Periods',
    'janby/period/opening': 'Opening | Opening hours',
    'janby/period/cleaning': 'Cleaning | Cleaning hours',

    // Monitor
    // Events
    Event: 'Event | Events',
    Stream: 'Sensor stream | Sensor streams',
    History: 'History | History',
    entity_event: '{entity} event | {entity} events',
    entityTrace: '{entity} trace | {entity} traces',
    status: 'Status | Statuses',
    aggregation: 'Aggregation | Aggregations',
    registered: 'Registered | Registered',
    expected: 'Expected | Expected',
    lastRegistered: 'Last activity | Last activities',
    NextActivity: 'Next activity | Next activities',
    firstRegistered: 'First activity | First activities',
    'janby/event': 'Ended | Endeds',
    'janby/event/schedule': 'Scheduled | Scheduleds',
    'janby/event/pending': 'Pending | Pendings',
    'janby/event/start': 'Started | Starteds',
    'janby/event/info': 'Information',
    'janby/event/ready': 'Ready | Readys',
    'janby/event/warning': 'Warning | Warnings',
    'janby/event/error': 'Error | Errors',
    'janby/event/modify': 'Manually modified by an operator',
    'janby/event/recalculate': 'Configuration automatially recalculated',
    'janby/event/end': 'Ended | Endeds',

    'janby/error': 'Error | Errors',
    'janby/error/unfinished': 'Finished before being ready',
    'janby/error/expire': 'Expired product',
    'janby/error/waste': 'Finished as waste',
    'janby/error/reuse': 'Finished to be reused',
    'janby/error/control/fail': 'Process control failed',
    'janby/error/device/disconnect': 'Device disconnected',
    'janby/error/time/modify': 'Time modified during process',
    'janby/error/temperature/modify': 'Temperature modified during process',
    'janby/error/time/recalculate': 'Time automatically recalculated',
    'janby/error/temperature/recalculate': 'Temperature automatically recalculated',
    'janby/error/time/reset': 'Cooking time reset during process',
    'janby/error/temperature/reset': 'Temperature reset during process',
    'janby/error/time/exceed': 'Maximum cooking time exceeded',
    'janby/error/time/hold/exceed': 'Maximum hold time exceeded',
    'janby/error/temperature/exceed': 'Maximum temperature exceeded',
    'janby/error/time/unreach': 'Minimum cooking time not reached',
    'janby/error/temperature/unreach': 'Minimum temperature not reached',

    'janby/warning': 'Warning | Warnings',
    'janby/warning/waste': 'Finished as waste',
    'janby/warning/reuse': 'Finished to be reused',
    'janby/warning/control/fail': 'Process control failed',
    'janby/warning/device/disconnect': 'Device disconnected',
    'janby/warning/time/modify': 'Time modified during process',
    'janby/warning/temperature/modify': 'Temperature modified during process',
    'janby/warning/time/recalculate': 'Time automatically recalculated',
    'janby/warning/temperature/recalculate': 'Temperature automatically recalculated',
    'janby/warning/time/reset': 'Cooking time reset during process',
    'janby/warning/temperature/reset': 'Temperature reset during process',
    'janby/warning/time/exceed': 'Maximum cooking time exceeded',
    'janby/warning/time/hold/exceed': 'Maximum hold time exceeded',
    'janby/warning/temperature/exceed': 'Maximum temperature exceeded',
    'janby/warning/time/unreach': 'Minimum cooking time not reached',
    'janby/warning/temperature/unreach': 'Minimum temperature not reached',

    'janby/info': 'Info message',
    'janby/info/serve': 'Product served',
    'janby/info/control/fulfil': 'Process control fulfilled',

    // Incidence
    Incidence: 'Incidence | Incidences',
    Message: 'Message | Messages',

    // Notification
    Notification: 'Notification | Notifications',
    'janby/notification/report/registered/': 'New report | New reports',
    'janby/notification/device/registered/': 'New device | New devices',
    'janby/notification/trace/warning/': 'Process warning | Process warnings',
    'janby/notification/trace/error/': 'Process error | Process errors',
    'janby/notification/test/': 'Test notification | Test notifications',
    'janby/notification/': 'Notification | Notifications',
    resolved: 'Resolved | Resolved',
    resolveOldestNumber: 'Resolve oldest {number} | Resolve oldest {number}',
    NotificationForwarder: 'Forwarder | Forwarders',
    matcher: 'Match | Matchs',
    forwardTo: 'Send to | Send to',
    forwardVia: 'Send via | Send via',
    'janby/notification/forwarder/': 'Unknown forwarder | Unknown forwarders',
    'janby/notification/forwarder/email/': 'Email',
    'janby/notification/forwarder/sms/': 'SMS',
    'janby/notification/forwarder/telegram/': 'Telegram',
    'janby/notification/forwarder/whatsapp/': 'WhatsApp',
    'janby/notification/forwarder/pubsub/': 'Janby Device',

    // Attachment
    Attachment: 'Attachment | Attachments',

    // Labeling
    // Batches
    LabelBatch: 'Batch | Batches',
    LabelBatchItem: 'Batch item | Batch items',
    labelingData: 'Labeling data',
    labelingData_desc: 'Labels identify products and configure devices to elaborate them',
    allowPrintLabelsForEntity: 'Allow printing labels for this {entity} | Allow printing labels for these {entity}',
    Item: 'Item | Items',
    Label: 'Label | Labels',
    labelIsPending: 'Pending to be consumed | Pending to be consumed',
    serial: 'Serial | Serials',
    mask: 'Mask | Masks',
    noLabelBatchId: 'Not batched | Not batched',
    labelCount: 'Label amount in batch | Label amount in batches',
    servingCount: 'Serving amount | Serving amount',
    isSerial: 'Serial number in label | Serial numbers in labels',
    'janby/label/cooking/individual': 'Individual labels',
    'janby/label/cooking/range': 'Range label',
    'janby/label/cooking/step': 'Label per elaboration step',
    scannedLabel: 'Scanned label | Scanned labels',
    serve_pending: 'Pending | Pending',
    batchPattern: 'Pattern to generate batch | Patterns to generate batches',
    batchPattern_desc: 'Batch identifier generator pattern. Batch identifiers are composed of letters and numbers and can make use of special codes to inject data such as date, day or serial number. You can inject:<br/>(S): Product serial number<br/>(BS): Batch serial number<br/>(Y): Batch packing year<br/>(M): Batch packing month:<br/>(D): Batch packing day<br/>(R): Random code',
    batchItem: 'Label in batch | Labels in each batch',
    batchItem_desc: 'Default amount of labels to print per batch. When printing you will be able to change the number of items of the printing batch.',
    countPerBatchItem: '{unit} in each bag | {unit} in each bag',
    countPerBatchItem_desc: 'Amount of product contained in each bag/format | Amount of product contained in each bag/format',
    servingCount: 'Amount of servings | Amount of servings',
    countPerServing: '{unit} per serving | {unit} per servings',
    countPerServing_desc: 'Amount of product contained in each serving | Amount of product contained in each serving',
    bestBeforeTime: 'Recommended consumption time | Recommended consumption times',
    expirationTime: 'Expiration time | Expiration times',
    expiration_nearer_than_period: 'Expiration is nearer than {period} | Expirations are nearer than {period}',
    expiration_with_pending: 'Expired with not used products | Expired with not used products',

    // Templates
    LabelTemplate: 'Label print template | Label print templates',
    LabelTemplateBlock: 'Label template fields | Label templates fields',
    "janby/labeling/template/label/name/": "Full name (Product + Batching + Batch) | Full names (Product + Batching + batch)",
    'janby/labeling/template/organization/name/': 'Organization name | Organization names',
    'janby/labeling/template/organization/vatId/': 'Organization VAT ID | Organization VAT IDs',
    'janby/labeling/template/organization/health/': 'Health registry | Health registries',
    'janby/labeling/template/organization/web/': 'Organization Web | Organization Webs',
    'janby/labeling/template/organization/address/': 'Organization address | Organization addresses',
    'janby/labeling/template/organization/metas/': 'Organization public additional properties | Organization public additional properties',
    'janby/labeling/template/product/name/': 'Product name | Product names',
    'janby/labeling/template/product/metas/': 'Product public additional properties | Product public additional properties',
    'janby/labeling/template/batching/name/': 'Batching format name | Batching format names',
    'janby/labeling/template/batching/weight/': 'Batching format weight | Batching format weights',
    'janby/labeling/template/batching/expectedCount/': 'Expected product amount | Expected product amount',
    'janby/labeling/template/batching/servingCount/': 'Expected servings | Expected servings',
    'janby/labeling/template/batching/metas/': 'Batching format public additional properties | Batching format public additional properties',
    'janby/labeling/template/task/name/': 'Task name | Task names',
    'janby/labeling/template/label/batchId/': 'Batch ID | Batch IDs',
    'janby/labeling/template/label/batchId+batchItem/': 'Batch ID and portion | Batch IDs and portions',
    'janby/labeling/template/label/registered/': 'Batch creation date | Batch creation dates',
    'janby/labeling/template/label/bestBefore/': 'Best before date | Best before dates',
    'janby/labeling/template/label/expiration/': 'Expiration date | Expiration dates',
    'janby/labeling/template/label/barcode/': 'Barcode | Barcodes',
    'janby/labeling/template/label/bytecode/': 'QR Code | QR code',
    'janby/labeling/template/task/name/': 'Task name | Task names',
    'janby/labeling/template/task/description/': 'Task description | Task descriptions',
    'janby/labeling/template/hspace/': 'Horizontal space | Horizontal spaces',
    'janby/labeling/template/hline/': 'Horizontal line | Horizontal lines',

    // Trace
    Trace: 'Trace | Traces',
    timeline: 'Timeline | Timelines',

    // Order
    Order: 'Order | Orders',
    OrderBatch: 'Order | Orders',
    OrderBatchItem: 'Order item | Order items',
    OrderForwarder: 'Receive order in device | Receive orders in devices',
    orderForwarderLocation: 'Orders received in this and child locations/ubications',
    'janby/order/pending': 'Pending | Pending',
    'janby/order/cancel': 'Canceled | Canceled',
    'janby/order/start': 'Started | Started',
    'janby/order/end': 'Ended | Ended',

    // Innovation
    Innovation: 'Test Kitchen | Test Kitchens',
    Experiment: 'Experiment | Experiments',
    ExperimentQualityParameter: 'Quality parameter | Quality parameters',

    // Stock
    Stock: 'Stock | Stocks',
    Evolution: 'Evolution | Evolutions',
    StockCheckpoint: 'Stock checkpoint | Stock checkpoints',
    CheckpointBatch: 'Checkpoint Group | Checkpoint Groups',
    loadCurrentStock: 'Load current stock | Load current stocks',
    StockVariation: 'Variation | Variations',
    StockTransfer: 'Transfer | Transfers',
    transferSource: 'Transfer origin | Transfer origins',
    transferTarget: 'Transfer destination | Transfer destinations',
    stockIncrease: 'Increase | Increases',
    stockDecrease: 'Decrease | Decreases',
    Waste: 'Waste | Wastes',
    outOfStock: 'Out of stock | Out of stocks',
    availableStock: 'Available stock | Available stocks',
    entityStock: '{entity} stock | {entity} stock',
    stockEstimatedAvailability: 'Estimated availability | Estimated availabilities',
    isPositiveCount: 'Positive variation | Positive variations',
    isNegativeCount: 'Negative variation | Negative variations',
    stockVariation: 'Stock variation | Stock variations',
    stockVariation_desc: 'Amount varied last {period}',
    stockVariationPerPeriod: '{period} variation | {period} variations',
    stockCovered: '{period} covered | {period} covered',
    stockCovered_desc: 'Availability based on last {period} variation.',
    historyCanBeLimited: 'Shown data can be limited to only the {days} day history your license allows to see.',

    // UserControl
    UserControl: 'User Control | User Control',
    UserToken: 'Identity token | Identity token',
    tokenPIN: 'MAC or PIN code | MACs or PIN codes',
    tokenPIN_desc: 'MAC address of the token/wereable or PIN code that is used to identify the user',
    tokenLocations_desc: 'Locations where the token/wereable is used to identify the user',

    // Store
    Store: 'Store | Stores',
    StorePurchase: 'Purchase/Subscription | Puchases/Subscriptions',
    nonSubscriptionPurchase: 'Accessory or non subscription product | Accessories or non subscription products',
    priceAmount: 'Amount | Amounts',
    tax: 'Tax | Taxes',
    shipping: 'Shipping | Shippings',
    shippingTax: 'Shipping tax | Shippings taxes',
    discount: 'Discount | Discounts',
    discountTax: 'Discount tax | Discounts taxes',

    // Haccp
    Haccp: 'Haccp | Haccps',
    HaccpPoint: 'Critical Control point | Critical Control points',
    HaccpPointOption: 'Corrective Action / Option | Corrective Actions / Options',
    Trigger: 'When to trigger | When to trigger',
    screenMessage: 'Message in screen | Messages in screen',
    'janby/haccp/point/': 'Critical Control point | Critical Control points',
    'janby/haccp/point/confirm/': 'Chose only one option | Chose only one option',
    'janby/haccp/point/select/': 'Multiple option selection | Multiple option selections',
    'janby/haccp/point/datetime/date/': 'Select Date Haccp point | Select Date Haccp points',
    'janby/haccp/point/datetime/time/': 'Select Time Haccp point | Select Time Haccp points',
    'janby/haccp/point/user/': 'Select User Haccp point | Select User Haccp points',
    
    // Alert
    'janby/alert/labeling/': 'Labelled product alert | Labelled product alerts',
    'janby/alert/haccp/': 'Haccp alert | Haccp alerts',
    'janby/alert/product/foodsafety/': 'Food safety alert | Food safety alerts',
    'janby/alert/product/': 'Product issue alert | Product issue alerts',

    // Planning
    Planning: 'Planning | Planning',
    Task: 'Task | Tasks',
    SubTask: 'SubTask | SubTasks',
    parent: 'Parent | Parents',
    hierarchy: 'Hierarchy | Hierarchies',
    taskAction: 'Action to perform in the task | Actions to perform in the tasks',
    'janby/task/pending/': 'Pending | Pending',
    'janby/task/cancel/': 'Canceled | Canceled',
    'janby/task/start/': 'Started | Started',
    'janby/task/end/': 'Ended | Ended',
    'janby/task/pause/': 'Paused | Paused',
    'janby/task/lock/': 'Locked | Locked',
    recurrence: 'Recurrence | Recurrences',
    recurrence_desc: 'How often the task is repeated',
    'janby/recurrence/none': 'None | None',
    'janby/recurrence/day': 'Repeat every day | Repeat every day',
    'janby/recurrence/day_desc': 'Triggered every day, it will take data from the triggering moment and the previous 24 hours',
    'janby/recurrence/week': 'Repeat every week | Repeat every week',
    'janby/recurrence/week_desc': 'Triggered every 7 days. It will take data from the triggering moment and the previous 7 days',
    'janby/recurrence/month': 'Repeat every month | Repeat every month',
    'janby/recurrence/month_desc': 'Triggered every month. It will take data from the triggering moment and the previous 30/31 days',
    instructions: 'Instructions | Instructions',
    duration: 'Duration | Durations',
    expectedDuration: 'Expected duration | Expected durations',
    Forecaster: 'Forecaster | Forecasters',
    forecastingCondition: 'Forecasting condition | Forecasting conditions',
    Pattern: 'Pattern | Patterns',
    'janby/pattern/dayhours/': 'Day hours pattern',
    'janby/pattern/weekdays/': 'Week days pattern',
    'janby/pattern/weekhours/': 'Week hours pattern',
    'janby/pattern/monthdays/': 'Month days pattern',
    'janby/pattern/monthhours/': 'Month hours pattern',

    // Provider
    EquipmentProvider: 'Manufacturer | Manufacturers',
    path: 'Path | Paths',
    Equipment: 'Equipment | Equipments',
    DevicePosition: 'Position inside device | Positions inside device',
    ExecutableAction: 'Executable action | Executable actions',
    InstallableAction: 'Installable action | Installable actions',
    SchedulableAction: 'Schedulable action | Schedulable actions',
    Sensor: 'Sensor | Sensors',
    Software: 'Software/Firmware | Softwares/Firmwares',
    'janby/sensor/temperature': 'Temperature | Temperatures',
    'janby/sensor/humidity': 'Humidity | Humidities',
    'janby/sensor/temperature/water': 'Water temperature | Water temperatures',
    'janby/sensor/temperature/probe': 'Probe temperature | Probe temperatures',
    'janby/sensor/battery/level': 'Battery level | Battery levels',
    'sammic/sensor/smartvide/water/temperature': 'Sammic SmartVide Water temperature sensor',
    'sammic/sensor/smartvide/probe/temperature': 'Sammic SmartVide temperature probe',
    freezer: 'Freezer | Freezers',
    fridge: 'Fridge | Fridges',
    oven: 'Oven | Ovens',
    blastChiller: 'Blast chiller | Blast chillers',

    // Affiliate
    Affiliate: 'Affiliate | Affiliates',

    // Reporting
    Report: 'Report | Reports',
    ScheduledReport: 'Scheduled report template | Scheduled report templates',
    ReportValidation: 'Report validation | Report validations',
    Insight: 'Insights | Insights',
    thematicReport: 'Thematic report | Thematic reports',
    nextReportGeneration: 'Next report generation | Next report generations',
    nextReportGeneration_desc: 'Next recurrent report generation date',
    'janby/report/entity/events': '{entity} report | {entity} reports',
    'janby/report/action/events': 'Cooking report | Cooking reports',
    'janby/report/haccp/point/events': 'Haccp CCP report | Haccp CCP reports',
    'janby/report/location/events': 'Location activity report | Locations activity reports',
    'janby/report/labeling/expirations': 'Next expiring products report | Next expiring products reports',
    'janby/report/stock/variations': 'Stock variations report | Stock variations reports',

    // Food
    Food: 'Food | Foods',
    FoodPasteurization: 'Lethality/Pasteurization | Lethality/Pasteurizations',
    food_sw: 'Chicken | Chicken',
    food_sx: 'Beef | Beef',
    food_1yxt6: 'Beef (Cooked) | Beef (Cooked)',
    food_1ytst: 'Beef (Corned) | Beef (Corned)',
    food_23y: 'Beef (Roast) | Beef (Roast)',
    food_su: 'Pork | Pork',
    food_1on: 'Rabbit | Rabbit',
    food_zq: 'Turkey | Turkey',
    food_1jc: 'Lamb | Lamb',
    food_29z: 'Patty | Patties',

    // Integration
    ThirdPartyConnector: 'Integration | Integrations',
    Connector: 'Conector | Conectors',
    Webhook: 'Webhook | Webhooks',
    secret: 'Secret | Secrets',
    secretForAPI_desc: 'If element is not public, caller must provide these secret when calling the API in order to see this element',
    isPublicForAPI_desc: 'If made public, it will be visible in API calls',
    lastRun: 'Last invocation | Last invocations',
    costCenter: 'Cost center | Cost centers',
    recipe: 'Recipe | Recipes',
    plate: 'Plate | Plates',
    subRecipe: 'Subrecipe | Subrecipes',
    outlet: 'Outlet | Outlets',
    nextSync: 'Next automatic synchronization date | Next automatic synchronization dates',
    processMayTakeLong: 'This process may take several minutes | These processes may take several minutes',
    integrationDataPolicy: 'NOTICE: JANBY only stores the minimum required information for locations, products, orders and elaborations that need to be identified for the cooking processes of your organization. In no case data regarding economic transactions, product pricing or restaurant customers is stored nor used.',

    // Showcase
    Gallery: 'Gallery | Galleries',
    Media: 'Media | Medias',
    publicHtml: 'Additional text | Additional texts',
    caption: 'Caption | Captions',
    recommendedEntity: 'Recommended {entity} | Recommended {entity}',

    // Crm
    Crm: 'Customer Support | Customers Support',
    CrmCompany: 'Company | Companies',
    CrmContact: 'Contact | Contacts',
    CrmEvent: 'Event/Meeting | Events/Meetings',
    CrmTicket: 'Support Ticket | Support Tickets',
    Attendee: 'Attendee | Attendees',
    Calendar: 'Calendar | Calendars',
    upcoming_entity: 'Upcoming {entity} | Upcoming {entity}',
    expectedStart: 'Expected start | Expected starts',
    maximumStart: 'Maximum start | Maximum starts',
    expectedReady: 'Expected ready | Expected ready',
    maximumReady: 'Maximum ready | Maximum ready',
    expectedEnd: 'Expected end | Expected ends',
    maximumEnd: 'Maximum end | Maximum ends',
    activeFrom: 'Active from | Active froms',
    activeTo: 'Active to | Active tos',
    repeatEvery: 'Repeat every | Repeat everys',
    companyPosition: 'Company position | Company positions',
    priority: 'Priority | Priorities',
    category: 'Category | Categories',
    employee: 'Employee | Employees',
    thankMessage: 'Thank you for your message. We will get back to you as soon as possible.',
    'janby/crm/event/email': 'E-mail | E-mail',
    'janby/crm/event/email/out': 'Outgoing email | Outgoing emails',
    'janby/crm/event/email/in': 'Email received | Emails received',
    'janby/crm/event/call': 'Call | Calls',
    'janby/crm/event/call/out': 'Call to customer | Calls to customer',
    'janby/crm/event/call/out/missed': 'Unsuccessful call to customer | Unsuccessful calls to customer',
    'janby/crm/event/call/in': 'Incoming call | Incoming calls',
    'janby/crm/event/call/in/missed': 'Missed call | Missed calls',
    'janby/crm/event/visit': 'Visit | Visits',
    'janby/crm/event/visit/out': 'Visit to customer | Visits to customer',
    'janby/crm/event/visit/in': 'Vist at Janby | Visits at Janby',
    'janby/crm/event/meeting': 'Meeting | Meetings',
    'janby/crm/event/meeting/video': 'Videomeeting | Videomeetings',
    'janby/crm/event/meeting/in': 'Meeting at Janby | Meetings at Janby',
    'janby/crm/event/meeting/out': 'Meeting at customers | Meetings at customers',

    // Others
    plural_ex: 'You have {n, plural, =0{no messages} one{1 message} other{# messages}}.',
    select_ex: '{gender, select, male{He} female{She} other{They}} liked this.',
    number_ex: 'Current Percent: {current, number, percent}',
    time_ex: 'Current Time: {current, time, short}',

    // Units
    'janby/unit/time': 'Time | Times',

    'janby/unit/temperature': 'Temperature | Temperatures',
    'janby/unit/temperature/C': 'Celsius (ºC) | Celsius (ºC)',
    'janby/unit/temperature/F': 'Fahrenheit (ºF) | Fahrenheit (ºF)',
    'janby/unit/percentage': 'Percentage | Percentages',

    'janby/unit/weight': 'Weight | Weight',
    'janby/unit/weight/g': 'Gram (g) | Grams (g)',
    'janby/unit/weight/kg': 'Kilogram (kg) | Kilograms (kg)',
    'janby/unit/weight/mg': 'Miligram (mg) | Miligrams (mg)',
    'janby/unit/weight/oz': 'Ounce (oz) | Ounces (oz)',
    'janby/unit/weight/lb': 'Pound (lb) | Pounds (lb)',

    'janby/unit/length': 'Length | Length',
    'janby/unit/length/m': 'Meter (m) | Meters (m)',
    'janby/unit/length/cm': 'Centimeter (cm) | Centimeters (cm)',
    'janby/unit/battery/level': 'Battery level | Battery levels',

    'janby/unit': 'Unit | Units',
    'janby/unit/serving': 'Serving | Servings',
    'janby/unit/leaf': 'Leaf | Leaves',
    'janby/unit/slice': 'Slice | Slices',
    'janby/unit/calory': 'Calory | Calories',
    'janby/unit/rating/stars/': 'Star | Stars',

  }
}
