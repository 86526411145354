<template>

      <!-- Schedules and patterns -->
      <SidenavSection v-if="permissions && permissions.RO >= 4"
        :name="$tc('m.ThirdPartyConnector')"
        icon="code"
        :navs="[
          { name: 'Cloud API', href: `/#/integration/api`, active: '/integration/api' },
          { name: $tc('m.Webhook', 2), href: `/#/integration/webhooks`, active: '/integration/webhooks' },
          { name: 'GastroKaizen', href: `/#/integration/gastrokaizen`, active: '/integration/gastrokaizen' },
          { name: 'Revo Xef', href: `/#/integration/revoxef`, active: '/integration/revoxef', minPermissions: { RO: 99 } },
          { name: 'Tspoonlab', href: `/#/integration/tspoonlab`, active: '/integration/tspoonlab', minPermissions: { RO: 99 } },
          { name: 'Odoo', href: `/#/integration/odoo`, active: '/integration/odoo', minPermissions: { RO: 99 } },
          { name: 'Holded', href: `/#/integration/holded`, active: '/integration/holded', minPermissions: { RO: 99 } },
          { name: 'EtPOS', href: `/#/integration/etpos`, active: '/integration/etpos' },
          { name: 'BarTender', href: `/#/integration/bartender`, active: '/integration/bartender', minPermissions: { RO: 99 } },
          { name: 'Glop', href: `/#/integration/glop`, active: '/integration/glop', minPermissions: { RO: 99 } },
          { name: 'Orderry', href: `/#/integration/orderry`, active: '/integration/orderry', minPermissions: { RO: 99 } },
          { name: 'Zapier', href: `/#/integration/zapier`, active: '/integration/zapier' },
          // { name: 'Ágora Restaurant', href: `/#/integration/agora`, active: '/integration/agora' },
          // { name: 'Apicbase', href: `/#/integration/apicbase`, active: '/integration/apicbase' },
          // { name: 'G-Stock', href: `/#/integration/gstock`, active: '/integration/gstock' },
          // { name: 'Janby Orders API', href: `/#/integration/ordering-janby`, active: '/integration/ordering-janby' },
        ]"
        :permissions="permissions"
        :layout="layout"
        visibleKey="integration"
        :visibles="visibles"
        :toggleVisible="toggleVisible"
        :isActive="isActive"
        />

</template>

<script>

import SidenavSection from './SidenavSection.vue'

export default {
  props: {
    permissions: {
      type: Object,
      default () { return {} }
    },
    layout: {
      type: Object,
      default () { return { sidenav: 0 } }
    },
    isActive: {
      type: Function
    },
    toggleVisible: {
      type: Function
    },
    visibles: {
      type: Array,
      default () { return [] }
    }
  },
  components: {
    SidenavSection,
  }
}
</script>
