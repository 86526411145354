const routes = [
  {
    path: '/integration/:connectorType/success',
    props: true,
    component: () => import('@/views/integration/connector/Success.vue')
  },
  {
    path: '/integration/success',
    props: true,
    component: () => import('@/views/integration/connector/Success.vue')
  },
  {
    path: '/integration/:connectorType/fail',
    props: true,
    component: () => import('@/views/integration/connector/Fail.vue')
  },
  {
    path: '/integration/fail',
    props: true,
    component: () => import('@/views/integration/connector/Fail.vue')
  },

  {
    path: '/integration/api/:nav?',
    props: true,
    component: () => import('@/views/integration/api/Api.vue')
  },

  {
    path: '/integration/bartender/:nav?',
    props: true,
    component: () => import('@/views/integration/bartender/Bartender.vue')
  },

  {
    path: '/integration/etpos/:nav?',
    props: true,
    component: () => import('@/views/integration/etpos/EtPos.vue')
  },

  {
    path: '/integration/zapier/:nav?',
    props: true,
    component: () => import('@/views/integration/zapier/Zapier.vue')
  },

  {
    path: '/integration/gastrokaizen/:nav?/:categoryId?',
    props: true,
    component: () => import('@/views/integration/gastrokaizen/GastroKaizen.vue')
  },

  {
    path: '/integration/revoxef/:nav?/:categoryId?',
    props: true,
    component: () => import('@/views/integration/revoxef/RevoXef.vue')
  },
  {
    path: '/integration/revoretail/:nav?/:categoryId?',
    props: true,
    component: () => import('@/views/integration/revoretail/RevoRetail.vue')
  },
  {
    path: '/integration/revoflow/:nav?/:categoryId?',
    props: true,
    component: () => import('@/views/integration/revoflow/RevoFlow.vue')
  },

  {
    path: '/integration/tspoonlab/:nav?/:categoryId?',
    props: true,
    component: () => import('@/views/integration/tspoonlab/Tspoonlab.vue')
  },

  {
    path: '/integration/odoo/:nav?/:categoryId?',
    props: true,
    component: () => import('@/views/integration/odoo/Odoo.vue')
  },

  {
    path: '/integration/holded/:nav?/:categoryId?',
    props: true,
    component: () => import('@/views/integration/holded/Holded.vue')
  },
  
  {
    path: '/integration/glop/:nav?/:categoryId?',
    props: true,
    component: () => import('@/views/integration/glop/Glop.vue')
  },

  {
    path: '/integration/orderry/:nav?/:categoryId?',
    props: true,
    component: () => import('@/views/integration/orderry/Orderry.vue')
  },

  {
    path: '/integration/agora/:nav?/:page?',
    props: true,
    component: () => import('@/views/integration/agora/Agora.vue')
  },

  {
    path: '/integration/apicbase/:nav?',
    props: true,
    component: () => import('@/views/integration/apicbase/Apicbase.vue')
  },

  {
    path: '/integration/gstock/:nav?/:page?',
    props: true,
    component: () => import('@/views/integration/gstock/GStock.vue')
  },

  {
    path: '/integration/ordering-janby/:nav?',
    props: true,
    component: () => import('@/views/integration/ordering.janby/OrderingJanby.vue')
  },

]

export default routes